<template>
  <el-dialog
    title="生成入库单"
    width="1400px"
    :visible.sync="selfShow"
    :close-on-click-modal="false"
  >
    <div>
      <e-form
        ref="refForm"
        :config="formConfig"
        :column="formColumn"
        :data.sync="selfDetail.formData"
      />
      <vxe-grid
        ref="refTable"
        :size="tableConfig.size"
        :border="tableConfig.border"
        :min-height="tableConfig.minHeight"
        :toolbar-config="tableConfig.toolbarConfig"
        :edit-config="tableConfig.editConfig"
        :row-config="tableConfig.rowConfig"
        :mouse-config="tableConfig.mouseConfig"
        :keyboard-config="tableConfig.keyboardConfig"
        :checkbox-config="tableConfig.checkboxConfig"
        :columns="tableColumn"
        :data="selfDetail.list"
        :show-footer="!!selfDetail.list.length"
      >
        <!-- slot_default -->
        <template #default_cai_gou_stock_create_date="{ row }">
          {{ row.cai_gou_stock_create_date ? row.cai_gou_stock_create_date.slice(0, 10) : '' }}
        </template>
        <template #default_song_huo_dan_photo_list="{ row }">
          <e-upload-image
            v-if="row.song_huo_dan_photo_list"
            size="30px"
            :list.sync="row.song_huo_dan_photo_list"
            isDelete
          />
        </template>
        <template #default_ru_ku_stock_counts_list="{ row }">
          {{ row.ru_ku_stock_counts_list.join(',') || 0 }}
        </template>

        <!-- slot_edit -->
        <template #edit_ru_ku_stock_counts="{ row }">
          <el-input v-model="row.ru_ku_stock_counts" type="number" size="mini" clearable />
        </template>
        <template #edit_cang_ku="{ row }">
          <el-select
            style="width: 100%"
            placeholder="请选择"
            size="mini"
            v-model="row.cang_ku"
            clearable
          >
            <el-option
              v-for="(item, index) in warehouseList"
              :key="index"
              :label="item.key"
              :value="item.val"
            />
          </el-select>
        </template>
      </vxe-grid>
    </div>
    <div slot="footer">
      <el-button size="small" @click="popupCancel">取消</el-button>
      <el-button size="small" type="primary" @click="popupConfirm">提交</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    list: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selfShow: false,
      selfDetail: {
        formData: {
          ru_ku_bill_date: this.$XEUtils.toDateString(this.$XEUtils.now(), 'yyyy-MM-dd'), // 入库日期
          ru_ku_code: '', // 入库单号
          ru_ku_remark: '' // 入库备注
        },
        list: []
      },
      formConfig: {
        inline: true,
        width: '240px'
      },
      formColumn: [
        {
          type: 'date',
          title: '入库日期',
          field: 'ru_ku_bill_date',
          required: true,
          format: 'yyyy-MM-dd',
          valueFormat: 'yyyy-MM-dd'
        },
        {
          type: 'text',
          title: '入库单号',
          field: 'ru_ku_code',
          placeholder: '自动生成',
          disabled: true
        },
        {
          type: 'text',
          title: '入库备注',
          field: 'ru_ku_remark'
        }
      ],
      tableColumn: [
        {
          fixed: 'left',
          type: 'checkbox',
          width: 40
        },
        {
          title: '序号',
          type: 'seq',
          width: 50
        },
        {
          title: '采购日期',
          field: 'cai_gou_stock_create_date',
          width: 104,
          slots: { default: 'default_cai_gou_stock_create_date' }
        },
        {
          title: '来源单号',
          field: 'cai_gou_code',
          width: 104
        },
        {
          title: '供应商',
          field: 'supplier_company_name'
        },
        {
          title: '业务员',
          field: 'saler_name',
          width: 60
        },
        {
          title: '送货单图片',
          field: 'song_huo_dan_photo_list',
          slots: { default: 'default_song_huo_dan_photo_list' }
        },
        {
          title: '品名/工艺',
          field: 'craft',
          width: 120
        },
        {
          title: '材质',
          field: 'texture',
          width: 60
        },
        {
          title: '规格',
          field: 'specification',
          width: 120
        },
        {
          title: '单位',
          field: 'unit',
          width: 60
        },
        {
          title: '待入库数量',
          field: 'un_ru_ku_count',
          width: 70
        },
        {
          title: '已入库数量',
          field: 'sum_ru_ku_stock_counts',
          width: 70,
          slots: { default: 'default_ru_ku_stock_counts_list' }
        },
        {
          title: '本次入库数量',
          field: 'ru_ku_stock_counts',
          width: 100,
          slots: { edit: 'edit_ru_ku_stock_counts' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '仓库',
          width: 100,
          field: 'cang_ku',
          slots: { edit: 'edit_cang_ku' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '商品备注',
          field: 'ru_ku_remark_stock'
        }
      ],
      warehouseList: []
    }
  },
  computed: {
    // 表格配置合并
    tableConfig() {
      let disabled = this.disabled
      let obj = {
        editConfig: {
          beforeEditMethod() {
            return !disabled
          }
        },
        keyboardConfig: {
          isEdit: !disabled
        },
        checkboxConfig: {
          checkMethod: ({ row }) => {
            return Number(row.cai_gou_stock_counts) > Number(row.sum_ru_ku_stock_counts)
          },
          visibleMethod({ row }) {
            return Number(row.cai_gou_stock_counts) > Number(row.sum_ru_ku_stock_counts)
          }
        }
      }
      let result = this.$util.deepMerge(this.$constant.tableDefaultConfig, obj)
      return result
    }
  },
  watch: {
    selfShow(val) {
      this.$emit('update:show', val)
    }
  },
  mounted() {
    this.selfShow = this.show
    this.getDetailData()
    this.getWarehouseList()
  },
  methods: {
    // 获取-详情数据
    getDetailData() {
      let ids = this.list.map((item) => item.cai_gou_stock_id).join(',')
      this.$api({
        method: 'get',
        url: '/admin/gcvip/ruku/buildRuKuBill',
        params: {
          cai_gou_stock_ids: ids
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          let list = this.$util.deepCopy(res.data.list)
          list.forEach((item) => {
            item.ru_ku_remark_stock = item.cai_gou_remark_stock
            // 初始化数量、单价、金额、选中状态
            if (!Number(item.ru_ku_stock_counts)) {
              item.ru_ku_stock_counts = this.$XEUtils.subtract(
                item.cai_gou_stock_counts,
                item.sum_ru_ku_stock_counts
              )
            }
            item.sum_ru_ku_stock_counts = item.sum_ru_ku_stock_counts || 0
          })
          this.selfDetail.list = this.$util.deepCopy(list)
          this.selfDetail.list.forEach((item) => {
            // 待入库数量 > 已入库数量
            if (Number(item.cai_gou_stock_counts) > Number(item.sum_ru_ku_stock_counts)) {
              this.$nextTick(() => {
                this.$refs.refTable.setCheckboxRow([item], true) // 默认选中
              })
            }
          })
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 获取-仓库列表
    getWarehouseList() {
      this.$api({
        method: 'post',
        url: '/admin/warehouseSetting',
        data: {
          currentPage: 1,
          pageSize: this.$constant.pageMax
        }
      })
        .then((res) => {
          if (res.status === 200 && res.data.state === 'ok') {
            let result = res.data.page.list.map((item) => {
              return {
                key: item.name,
                val: item.name
              }
            })
            this.warehouseList = result
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch(() => {})
        .then(() => {
          this.loading = false
        })
    },
    // 项-选择提示
    itemChoosePrompt() {
      this.$tool.tableChoosePrompt()
    },
    // 获取-当前复选行
    getCheckboxRecords() {
      return this.$refs.refTable.getCheckboxRecords()
    },
    // 弹窗取消
    popupCancel() {
      this.selfShow = false
      this.$emit('update:show', false)
    },
    // 弹窗确认
    popupConfirm() {
      if (!this.selfDetail.formData.ru_ku_bill_date) {
        this.$message.error('请选择入库日期')
        return
      }
      let rows = this.getCheckboxRecords()
      if (rows.length) {
        // 判断选中行本次入库数量是否都>0
        let isPass = rows.every((item) => !!Number(item.ru_ku_stock_counts))
        if (!isPass) {
          this.$message.error('选中行的本次入库数量必须>0')
          return
        }
      } else {
        this.itemChoosePrompt()
      }
      let params = {
        formData: {
          ...this.selfDetail.formData
        },
        list: [...rows]
      }
      this.$api({
        method: 'post',
        url: '/admin/gcvip/ruku/saveOrUpdate',
        data: params
      })
        .then((res) => {
          if (res.data.state === 'ok') {
            this.popupCancel()
            this.$emit('success')
            this.$message.success('保存成功')
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((error) => {
          this.$message.error(error)
        })
    }
  }
}
</script>

<style lang="less" scoped></style>
